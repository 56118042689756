import {defineComponent, onMounted, ref, inject} from "vue";
import {QrcodeStream} from 'vue3-qrcode-reader'
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'QRCodeDialog',
  components: {
    QrcodeStream
  },
  setup() {
    const { t, locale } = useI18n({ useScope: 'global' });
    const dialogRef: any = inject('dialogRef');
    let qrCodeError = "";
    const loading = ref(false);
    onMounted(() => {

    });

    const onDecode = (result: any) => {
      // console.log(result);
      dialogRef.value.close(result);
    }

    const onInit = async (promise) => {
      loading.value = true;
      try {
        await promise
      } catch (error: any) {
        if (error.name === 'NotAllowedError') {
          qrCodeError = t("qrCodeDialog.title");
        } else if (error.name === 'NotFoundError') {
          qrCodeError = t("qrCodeDialog.NotFoundError");
        } else if (error.name === 'NotSupportedError') {
          error.value = t("qrCodeDialog.NotSupportedError");
        } else if (error.name === 'NotReadableError') {
          qrCodeError = t("qrCodeDialog.NotReadableError");
        } else if (error.name === 'OverconstrainedError') {
          qrCodeError = t("qrCodeDialog.OverconstrainedError");
        } else if (error.name === 'StreamApiNotSupportedError') {
          qrCodeError = t("qrCodeDialog.StreamApiNotSupportedError");
        } else if (error.name === 'InsecureContextError') {
          qrCodeError = t("qrCodeDialog.InsecureContextError");
        } else {
          qrCodeError = t('qrCodeDialog.generalError', { name: error.name });
        }
        alert(qrCodeError)
        if (dialogRef.value.data.qrDialogOpened) {
          dialogRef.value.close();
        }
      } finally {
        loading.value = false;
        console.log("onInit done");
      }
    }
    return {
      onDecode,
      onInit,
      loading
    };
  }
})
