<template>
  <div>
    <qrcode-stream @decode="onDecode" @init="onInit">
      <div class="loading-spinner" v-show="loading">
        <div class="spinner"></div>
      </div>
    </qrcode-stream>
  </div>
</template>

<script lang="ts">
import s from './scripts/QRCodeDialog';

export default s;
</script>

<style scoped>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1000;
}

.spinner {
  border: 6px solid lightgray;
  border-top: 6px solid black;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
