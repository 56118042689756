<template>
  <div class="card" style="line-height: 15px; width: 140rem">
    <TabView lazy>
      <TabPanel :header="$t('calculation.group_view')">
        <DataTable
          :value="calculations"
          :loading="loading"
          :resizableColumns="true"
          tableStyle="width: 140rem"
          id="calculation_report"
          name="calculation_report"
          ref="dt"
          responsiveLayout="scroll"
          selectionMode="single"
          columnResizeMode="expand"
          showGridlines
          stripedRows
          groupRowsBy="contract"
          v-model:expandedRows="expandedRows"
          scrollable
          scrollHeight="60rem"
          dataKey="id"
          class="dark-header p-datatable-report text-sm">
          <template #header>
            <div id="header" class="table-header-container flex">
              <div class="flex mr-auto">
                <div class="px-1">
                  <Button
                    icon="pi pi-plus"
                    :label="$t('technologyReport.expand')"
                    @click="expandAll" />
                </div>
                <div class="px-1">
                  <Button
                    icon="pi pi-minus"
                    :label="$t('technologyReport.collapse')"
                    @click="collapseAll" />
                </div>
                <div class="px-1">
                  <Button
                    icon="pi pi-external-link"
                    :label="$t('calculationReport.export')"
                    @click="exportExcel()" />
                </div>
                <div class="px-1">
                  <Button
                    icon="pi pi-check-circle"
                    :label="$t('message.save')"
                    :disabled="store.state.calculationSaved"
                    @click="onSave()" />
                </div>
              </div>
              <div class="flex">
                <div class="flex items-center">
                  <div class="px-2">{{ t("workshops.work_price") }}:</div>
                  <div id="price" class="p-inputtext p-component mr-3">
                    {{
                      work_price.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                    €
                  </div>
                </div>
                <div class="flex items-center">
                  <div class="px-2">{{ t("workshops.margin") }}:</div>
                  <div class="p-inputtext p-component mr-3">
                    {{
                      (margin * 100).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                    %
                  </div>
                </div>
                <div class="flex items-center">
                  <div class="px-2">{{ t("workshops.fluctuations") }}:</div>
                  <div class="p-inputtext p-component mr-3">
                    {{
                      (fluctuations * 100).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                    %
                  </div>
                </div>
              </div>
            </div>
          </template>
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column
            field="contract"
            headerStyle="width: 20rem"
            :header="$t('sketch_grid.contract')">
          </Column>
          <Column
            field="creator"
            headerStyle="width: 20rem"
            :header="$t('calculationReport.creator')">
          </Column>
          <Column
            field="id"
            headerStyle="width: 20rem"
            :header="$t('calculationReport.id')">
          </Column>
          <Column
            field="name"
            headerStyle="width: 20rem"
            :header="$t('calculationReport.name')">
            <template #body="slotProps">
              <span style="text-align: center">{{
                slotProps.data.name.substring(
                  slotProps.data.name.indexOf(" ") + 1
                )
              }}</span>
            </template>
          </Column>
          <Column field="assembly" :header="$t('sketch_grid.assembly')">
          </Column>
          <Column
            field="work_price"
            bodyStyle="text-align:right"
            headerStyle="width: 8rem"
            footerStyle="text-align:right">
            <template #header>
              <span style="text-align: center" class="w-full">
                {{ t("calculationReport.work_price") }}
              </span>
            </template>
            <template #body="slotProps">
              <span style="text-align: center">
                {{
                  slotProps.data.work_price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                €
              </span>
            </template>
            <template #footer>
              <span style="text-align: center">
                {{
                  totalWork_price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                €
              </span>
            </template>
          </Column>
          <Column
            field="material_price"
            bodyStyle="text-align:right"
            headerStyle="width: 8rem"
            footerStyle="text-align:right">
            <template #header>
              <span style="text-align: center" class="w-full">
                {{ t("calculationReport.material_price") }}
              </span>
            </template>
            <template #body="slotProps">
              <span style="text-align: center">
                {{
                  slotProps.data.material_price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                €
              </span>
            </template>
            <template #footer>
              <span style="text-align: center">
                {{
                  totalMaterial_price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                €
              </span>
            </template>
          </Column>
          <Column
            bodyStyle="text-align:right"
            headerStyle="width: 9.1rem"
            footerStyle="text-align:right">
            <template #header>
              <span style="text-align: center" class="w-full">
                {{ t("calculationReport.total_price") }}
              </span>
            </template>
            <template #body="slotProps">
              <span
                >{{
                  (
                    slotProps.data.material_price + slotProps.data.work_price
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                €</span
              >
            </template>
            <template #footer>
              <span style="text-align: center">
                {{
                  totalPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                €
              </span>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div class="elements-subtable">
              <DataTable
                :value="slotProps.data.prices"
                responsiveLayout="scroll">
                <Column
                  field="article"
                  headerStyle="width: 6rem"
                  :header="$t('calculationReport.article')">
                  <template #body="slotProps">
                    <span style="text-align: center">{{
                      slotProps.data.article
                    }}</span>
                  </template>
                </Column>
                <Column
                  field="position"
                  headerStyle="width: 4rem"
                  :header="$t('calculationReport.position')">
                  <template #body="slotProps">
                    <span style="text-align: center">{{
                      slotProps.data.position
                    }}</span>
                  </template>
                </Column>
                <Column
                  field="element"
                  headerStyle="width: 12.9rem"
                  :header="$t('calculationReport.element')">
                  <template #body="slotProps">
                    <span style="text-align: center">{{
                      t("priceList." + slotProps.data.element)
                    }}</span>
                  </template>
                </Column>
                <Column
                  field="diameter"
                  headerStyle="width: 6rem"
                  bodyStyle="text-align:right">
                  <template #header>
                    <span style="text-align: center" class="w-full">
                      {{ t("calculationReport.description") }}
                    </span>
                  </template>
                </Column>
                <Column
                  field="LDR"
                  headerStyle="width: 6rem"
                  bodyStyle="text-align:right"
                  :header="$t('calculationReport.val1')">
                </Column>
                <Column
                  field="L1"
                  headerStyle="width: 4rem"
                  bodyStyle="text-align:right"
                  :header="$t('calculationReport.val2')">
                </Column>
                <Column
                  field="Lm"
                  headerStyle="width: 4rem"
                  bodyStyle="text-align:right"
                  :header="$t('calculationReport.val3')">
                </Column>
                <Column
                  field="L2"
                  headerStyle="width: 4rem"
                  bodyStyle="text-align:right"
                  :header="$t('calculationReport.val4')">
                </Column>
                <Column
                  field="quantity"
                  headerStyle="width: 6rem"
                  bodyStyle="text-align:right"
                  :header="$t('calculationReport.quantity')">
                </Column>
                <Column
                  field="units"
                  headerStyle="width: 5rem"
                  :header="$t('calculationReport.units')">
                </Column>
                <Column
                  field="area_netto"
                  headerStyle="width: 8rem"
                  bodyStyle="text-align:right"
                  footerStyle="text-align:right">
                  <template #header>
                    {{ t("calculationReport.area_netto") }} [m<sup>2</sup>]
                  </template>
                  <template #body="slotProps">
                    <span style="text-align: center">
                      {{
                        slotProps.data.area_netto.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                      m<sup>2</sup>
                    </span>
                  </template>
                  <template #footer>
                    <span style="text-align: center">
                      {{
                        slotProps.data.area_netto.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                      m<sup>2</sup>
                    </span>
                  </template>
                </Column>
                <Column
                  field="waste"
                  headerStyle="width: 7rem"
                  bodyStyle="text-align:right"
                  :header="$t('calculationReport.waste')">
                </Column>
                <Column
                  field="area_brutto"
                  headerStyle="width: 8rem"
                  bodyStyle="text-align:right"
                  footerStyle="text-align:right">
                  <template #header>
                    {{ t("calculationReport.area_brutto") }} [m<sup>2</sup>]
                  </template>
                  <template #body="slotProps">
                    <span style="text-align: center">
                      {{
                        slotProps.data.area_brutto.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                      m<sup>2</sup>
                    </span>
                  </template>
                  <template #footer>
                    <span style="text-align: center">
                      {{
                        slotProps.data.area_brutto.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                      m<sup>2</sup>
                    </span>
                  </template>
                </Column>
                <Column
                  field="thickness"
                  headerStyle="width: 4rem"
                  :header="$t('calculationReport.thickness')"
                  bodyStyle="text-align:right"
                  footerStyle="text-align:right">
                  <template #body="slotProps">
                    <span style="text-align: center">
                      {{
                        slotProps.data.thickness.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                    </span>
                  </template>
                </Column>
                <Column
                  field="material"
                  headerStyle="width: 4rem"
                  :header="$t('calculationReport.material')">
                </Column>
                <Column
                  field="mass_netto"
                  headerStyle="width: 8rem"
                  :header="$t('calculationReport.weight_netto')"
                  bodyStyle="text-align:right"
                  footerStyle="text-align:right">
                  <template #body="slotProps">
                    <span style="text-align: center">
                      {{
                        slotProps.data.mass_netto.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                    </span>
                  </template>
                  <template #footer>
                    <span style="text-align: center">
                      {{
                        slotProps.data.mass_netto.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                      kg
                    </span>
                  </template>
                </Column>
                <Column
                  field="mass_brutto"
                  headerStyle="width: 8rem"
                  :header="$t('calculationReport.weight_brutto')"
                  bodyStyle="text-align:right"
                  footerStyle="text-align:right">
                  <template #body="slotProps">
                    <span style="text-align: center">
                      {{
                        slotProps.data.mass_brutto.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                      kg
                    </span>
                  </template>
                  <template #footer>
                    <span style="text-align: center">
                      {{
                        slotProps.data.mass_brutto.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                      kg
                    </span>
                  </template>
                </Column>
                <Column
                  field="work_time"
                  headerStyle="width: 8rem"
                  :header="$t('calculationReport.work_time')"
                  bodyStyle="text-align:right"
                  footerStyle="text-align:right">
                  <template #body="slotProps">
                    <span style="text-align: center">
                      {{
                        slotProps.data.work_time.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                      min
                    </span>
                  </template>
                  <template #footer>
                    <span style="text-align: center">
                      {{
                        slotProps.data.work_time.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                      min
                    </span>
                  </template>
                </Column>
                <Column
                  field="work_price"
                  bodyStyle="text-align:right"
                  headerStyle="width: 8rem"
                  footerStyle="text-align:right">
                  <template #header>
                    <span style="text-align: center" class="w-full">
                      {{ t("calculationReport.work_price") }}
                    </span>
                  </template>
                  <template #body="slotProps">
                    <span style="text-align: center">
                      {{
                        slotProps.data.work_price.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                      €
                    </span>
                  </template>
                  <template #footer>
                    <span style="text-align: center">
                      {{
                        slotProps.data.work_price.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                      €
                    </span>
                  </template>
                </Column>
                <Column
                  field="material_price"
                  bodyStyle="text-align:right"
                  headerStyle="width: 8rem"
                  footerStyle="text-align:right">
                  <template #header>
                    <span style="text-align: center" class="w-full">
                      {{ t("calculationReport.material_price") }}
                    </span>
                  </template>
                  <template #body="slotProps">
                    <span style="text-align: center">
                      {{
                        slotProps.data.material_price.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      }}
                      €
                    </span>
                  </template>
                  <template #footer>
                    <span style="text-align: center">
                      {{
                        slotProps.data.material_price.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      }}
                      €
                    </span>
                  </template>
                </Column>
                <Column
                  bodyStyle="text-align:right"
                  footerStyle="text-align:right"
                  headerStyle="width: 8rem">
                  <template #header>
                    <span style="text-align: center" class="w-full">
                      {{ t("calculationReport.total_price") }}
                    </span>
                  </template>
                  <template #body="slotProps">
                    <span
                      >{{
                        (
                          slotProps.data.material_price +
                          slotProps.data.work_price
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                      €</span
                    >
                  </template>
                  <template #footer>
                    <span style="text-align: center">
                      {{
                        slotProps.data.totalPrice.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                      €
                    </span>
                  </template>
                </Column>
              </DataTable>
            </div>
          </template>
          <template #groupheader="slotProps">
            <div class="flex align-items-center gap-2">
              <span>{{ t("info.assembly.contract") }}</span>
              <span>{{ slotProps.data.contract }}</span>
            </div>
          </template>
        </DataTable>
      </TabPanel>
      <TabPanel :header="$t('calculation.flat_view')">
        <DataTable
          :value="sketchCalculationsFlat"
          :loading="loading"
          :resizableColumns="true"
          ref="dt_flat"
          id="calculation_report_flat"
          name="calculation_report_flat"
          responsiveLayout="scroll"
          selectionMode="single"
          columnResizeMode="expand"
          showGridlines
          scrollable
          scrollHeight="900px"
          stripedRows
          class="dark-header p-datatable-report text-sm">
          <template #header>
            <div id="header" class="table-header-container flex justify-start">
              <div class="flex mr-auto">
                <div class="px-1">
                  <Button
                    icon="pi pi-external-link"
                    :label="$t('calculationReport.export')"
                    @click="exportExcelFlat()" />
                </div>
                <div class="px-1">
                  <Button
                    icon="pi pi-check-circle"
                    :label="$t('message.save')"
                    @click="onSave()"
                    :disabled="store.state.calculationSaved" />
                </div>
              </div>
              <div class="flex">
                <div class="flex items-center">
                  <div class="px-2">{{ t("workshops.work_price") }}:</div>
                  <div id="price" class="p-inputtext p-component mr-3">
                    {{
                      work_price.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                    €
                  </div>
                </div>
                <div class="flex items-center">
                  <div class="px-2">{{ t("workshops.margin") }}:</div>
                  <div class="p-inputtext p-component mr-3">
                    {{
                      (margin * 100).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                    %
                  </div>
                </div>
                <div class="flex items-center">
                  <div class="px-2">{{ t("workshops.fluctuations") }}:</div>
                  <div class="p-inputtext p-component mr-3">
                    {{
                      (fluctuations * 100).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                    %
                  </div>
                </div>
              </div>
            </div>
          </template>
          <Column field="contract" :header="$t('sketch_grid.contract')">
          </Column>
          <Column
            field="creator"
            :header="$t('calculationReport.creator')"></Column>
          <Column field="id" :header="$t('calculationReport.id')"> </Column>
          <Column
            field="name"
            headerStyle="width: 20rem"
            :header="$t('calculationReport.name')">
            <template #body="slotProps">
              <span style="text-align: center">{{
                slotProps.data.name.substring(
                  slotProps.data.name.indexOf(" ") + 1
                )
              }}</span>
            </template>
          </Column>
          <Column field="assembly" :header="$t('sketch_grid.assembly')">
          </Column>

          <Column field="article" :header="$t('calculationReport.article')">
            <template #body="slotProps">
              <span style="text-align: center">{{
                slotProps.data.article
              }}</span>
            </template>
          </Column>
          <Column field="position" :header="$t('calculationReport.position')">
            <template #body="slotProps">
              <span style="text-align: center">{{
                slotProps.data.position
              }}</span>
            </template>
          </Column>
          <Column field="element" :header="$t('calculationReport.element')">
            <template #body="slotProps">
              <span style="text-align: center">{{
                t("calculationReport." + slotProps.data.element)
              }}</span>
            </template>
          </Column>
          <Column
            field="diameter"
            :header="$t('calculationReport.description')"
            bodyStyle="text-align:right">
          </Column>
          <Column
            field="LDR"
            bodyStyle="text-align:right"
            :header="$t('calculationReport.val1')">
          </Column>
          <Column
            field="L1"
            bodyStyle="text-align:right"
            :header="$t('calculationReport.val2')">
          </Column>
          <Column
            field="Lm"
            bodyStyle="text-align:right"
            :header="$t('calculationReport.val3')">
          </Column>
          <Column
            field="L2"
            bodyStyle="text-align:right"
            :header="$t('calculationReport.val4')">
          </Column>
          <Column field="quantity" :header="$t('calculationReport.quantity')">
          </Column>
          <Column
            field="units"
            bodyStyle="text-align:right"
            :header="$t('calculationReport.units')">
          </Column>
          <Column
            field="area_netto"
            bodyStyle="text-align:right"
            footerStyle="text-align:right">
            <template #header>
              {{ t("calculationReport.area_netto") }} [m<sup>2</sup>]
            </template>
            <template #body="slotProps">
              <span style="text-align: center">
                {{
                  slotProps.data.area_netto.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                m<sup>2</sup>
              </span>
            </template>
            <template #footer>
              <span style="text-align: center">
                {{
                  totalArea_netto.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                m<sup>2</sup>
              </span>
            </template>
          </Column>
          <Column
            field="waste"
            bodyStyle="text-align:right"
            :header="$t('calculationReport.waste')">
          </Column>
          <Column
            field="area_brutto"
            bodyStyle="text-align:right"
            footerStyle="text-align:right">
            <template #header>
              {{ t("calculationReport.area_brutto") }} [m<sup>2</sup>]
            </template>
            <template #body="slotProps">
              <span style="text-align: center">
                {{
                  slotProps.data.area_brutto.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                m<sup>2</sup>
              </span>
            </template>
            <template #footer>
              <span style="text-align: center">
                {{
                  totalArea_brutto.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                m<sup>2</sup>
              </span>
            </template>
          </Column>
          <Column field="thickness" :header="$t('calculationReport.thickness')">
            <template #body="slotProps">
              <span style="text-align: center">
                {{
                  slotProps.data.thickness.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </span>
            </template>
          </Column>
          <Column field="material" :header="$t('calculationReport.material')">
          </Column>
          <Column
            field="mass_netto"
            bodyStyle="text-align:right"
            footerStyle="text-align:right"
            :header="$t('calculationReport.weight_netto')">
            <template #body="slotProps">
              <span style="text-align: center">
                {{
                  slotProps.data.mass_netto.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                kg
              </span>
            </template>
            <template #footer>
              <span style="text-align: center">
                {{
                  totalMass_netto.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                kg
              </span>
            </template>
          </Column>
          <Column
            field="mass_brutto"
            bodyStyle="text-align:right"
            footerStyle="text-align:right"
            :header="$t('calculationReport.weight_brutto')">
            <template #body="slotProps">
              <span style="text-align: center">
                {{
                  slotProps.data.mass_brutto.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                kg
              </span>
            </template>
            <template #footer>
              <span style="text-align: center">
                {{
                  totalMass_brutto.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                kg
              </span>
            </template>
          </Column>
          <Column
            field="work_time"
            bodyStyle="text-align:right"
            footerStyle="text-align:right"
            :header="$t('calculationReport.work_time')">
            <template #body="slotProps">
              <span style="text-align: center">
                {{
                  slotProps.data.work_time.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                min
              </span>
            </template>
            <template #footer>
              <span style="text-align: center">
                {{
                  totalWork_time.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                min
              </span>
            </template>
          </Column>
          <Column
            field="work_price"
            :header="$t('calculationReport.work_price')"
            footerStyle="text-align:right"
            bodyStyle="text-align:right">
            <template #body="slotProps">
              <span style="text-align: center">
                {{
                  slotProps.data.work_price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                €
              </span>
            </template>
            <template #footer>
              <span style="text-align: center">
                {{
                  totalWork_price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                €
              </span>
            </template>
          </Column>
          <Column
            field="material_price"
            footerStyle="text-align:right"
            :header="$t('calculationReport.material_price')"
            bodyStyle="text-align:right">
            <template #body="slotProps">
              <span style="text-align: center">
                {{
                  slotProps.data.material_price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                €
              </span>
            </template>
            <template #footer>
              <span style="text-align: center">
                {{
                  totalMaterial_price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                €
              </span>
            </template>
          </Column>
          <Column
            :header="$t('calculationReport.total_price')"
            bodyStyle="text-align:right"
            footerStyle="text-align:right">
            <template #body="slotProps">
              <span
                >{{
                  (
                    slotProps.data.material_price + slotProps.data.work_price
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                €</span
              >
            </template>
            <template #footer>
              <span style="text-align: center">
                {{
                  totalPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                €
              </span>
            </template>
          </Column>
        </DataTable>
      </TabPanel>
    </TabView>
  </div>
</template>

<script lang="ts">
import s from "./scripts/CalculationTable";
export default s;
</script>
<style lang="scss" scoped>
::v-deep(.p-datatable.p-datatable-report) {
  .p-column-filter-clear-button {
    visibility: collapse;
    display: none;
  }

  .p-datatable-tbody > tr > td {
    padding-top: 0.02rem;
    padding-bottom: 0.2rem;
  }

  .p-datatable-thead > tr > th {
    padding-top: 0.5rem;
    padding-bottom: 0.3rem;
  }

  .p-datatable-header {
    padding-top: 0.5rem;
    padding-bottom: 0.3rem;
  }

  .p-datatable-tfoot > tr > td {
    padding-top: 0.25rem;
    padding-bottom: 0.2rem;
  }
}
</style>
